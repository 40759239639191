<template>
  <div v-if="isLoading">
    <div>
      <h1 class="mr-sm-4 header-tablepage">LINE SETTING</h1>
    </div>
    <div class="title-panel mt-3">Line Setting</div>
    <div class="p-3 bg-white">
      <div
        class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
      >
        <InputText
          textFloat="Member Liff URL"
          placeholder="Member Liff URL"
          type="text"
          name="member_liff_url"
          class="w-100"
          v-model="form.memberLiffUrl"
        />
        <font-awesome-icon
          class="icon-copy"
          icon="copy"
          id="tooltip-memberLiffUrl"
          @click="copyWord('memberLiffUrl')"
          v-b-tooltip.hover
          title="Copy URL"
        />
        <b-tooltip
          target="tooltip-memberLiffUrl"
          ref="tooltip-memberLiffUrl"
          triggers="click"
          delay.hide="500"
        >
          <b>Copy !</b>
        </b-tooltip>
      </div>
      <div
        class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
      >
        <InputText
          textFloat="Campaign Liff URL"
          placeholder="Campaign Liff URL"
          type="text"
          name="campaign_liff_url"
          class="w-100"
          v-model="form.campaignLiffUrl"
        />

        <font-awesome-icon
          class="icon-copy"
          icon="copy"
          @click="copyWord('campaignLiffUrl')"
          id="tooltip-campaignLiffUrl"
          v-b-tooltip.hover
          title="Copy URL"
        />
        <b-tooltip
          target="tooltip-campaignLiffUrl"
          ref="tooltip-campaignLiffUrl"
          triggers="click"
          delay.hide="500"
        >
          <b>Copy !</b>
        </b-tooltip>
      </div>
    </div>
    <FooterAction routePath="/setting" @submit="saveForm()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "@/components/modal/ModalLoading";
export default {
  components: { InputText, ModalLoading },
  data() {
    return {
      isLoading: true,
      form: {
        memberLiffUrl: "",
        campaignLiffUrl: "",
      },
    };
  },
  mounted() {},

  created() {
    this.getList();
  },
  destroyed() {},
  methods: {
    async getList() {
      this.isLoading = false;
      const response = await this.$Axios(
        `${process.env.VUE_APP_API}/setting/getline`
      );
      this.form = response.data.detail;
      this.isLoading = true;
    },

    async saveForm() {
      this.$refs.modalLoading.show();
      this.$Axios
        .post(`${process.env.VUE_APP_API}/setting/updateline`, this.form)
        .then((res) => {
          if (res.data.result) {
            this.$swal2
              .fire(res.data.message, "", "success")
              .then((val) => val && this.$router.push("/setting"));
          } else {
            this.$swal2.fire(res.data.message, res.data.detail || "", "error");
          }
          this.$refs.modalLoading.hide();
        });
    },
    copyWord(key) {
      if (!this.form[key]) {
        return;
      }
      this.$copyText(this.form[key]);
      // this.$clipboard(this.form[key]);
      this.$refs["tooltip-" + key].$emit("open");
      setTimeout(() => {
        this.$refs["tooltip-" + key].$emit("close");
      }, 500);
    },
  },
};
</script>
<style scoped>
label.title {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  padding: 0px 15px 0 15px !important;
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
.icon-copy {
  margin-top: 0.4rem;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
